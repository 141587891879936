import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { LpPage02Register } from "../crud/createRegister"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import axios from "axios"
import { navigate } from "gatsby"
import Recaptcha from "react-recaptcha"
import useSSR from "use-ssr"
import { Seo } from "../components/seo"
import banner from "../images/lp02/919mexicoBanner.png"
import down_arrow_icon from "../images/lp02/down_arrow_icon.png"



import logo1 from "../images/919_mex_logo_800_retina.webp"
import ref1 from "../images/lp02/ref1.png"
import ref2 from "../images/lp02/ref2.png"
import ref3 from "../images/lp02/ref3.png"
import money from "../images/lp02/money-logo.svg"
import location from "../images/lp02/location-logo.svg"
import phone from "../images/lp02/phone-logo.svg"
import mail from "../images/lp02/mail-logo.svg"
import world from "../images/lp02/world-logo.svg"
import why_icon1 from "../images/lp02/why-icon1.png"
import why_icon2 from "../images/lp02/why-icon2.png"
import why_icon3 from "../images/lp02/why-icon3.png"
import why_icon4 from "../images/lp02/why-icon4.png"
import why_icon5 from "../images/lp02/why-icon5.png"
import difference from "../images/lp02/difference-section.png"
import happy from "../images/lp02/icon-happy.png"
import unhappy from "../images/lp02/icon-unhappy.png"
import building from "../images/lp02/building.png"

export const Lp02 = styled.div`
  #primary-banner {
    height: 600px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
    padding: 0;
    display: flex;
    align-items: center;
    background: url(${banner}) no-repeat center center/cover;
  }
  select {
    background: url(${down_arrow_icon}) no-repeat 98% #f0f0f0;
    -webkit-appearance: none;
  }
  @media (max-width: 767px) {
    #primary-banner {
      height: 193px;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    #primary-banner h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 48px;
      padding: 0;
      margin: 0;
    }
  }
`

const Lp_02 = () => {

  const { isBrowser } = useSSR()

  const [userData, setUserData] = useState({
    FullName: "",
    Email: "",
    ConfirmEmail: "",
    Country: "",
    OCountry: "",
    Request: "",
    messageError: {},
    isVerified: false,
  })
  useEffect(() => {
    getIpInfo()
  })
  const [ip, setIp] = useState("No")
  const [ipAddress, setIpAddress] = useState("No")
  const getIpInfo = async () => {
    const res = await axios.get("https://geolocation-db.com/json/")
    setIp(res.data.IPv4)
    setIpAddress(res.data.country_name)
  }

  const emailRegex = RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
  const [loading, setLoading] = useState(false)
  const handleChange = e => {
    const { name, value } = e.target
    setUserData(prevState => ({
      ...prevState,
      [name]: value,
    }))

    let errors = userData.messageError
    switch (name) {
      case "FullName":
        errors.FullName =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break

      case "Email":
        errors.Email =
          value === ""
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : "" || emailRegex.test(value)
            ? ""
            : "これは有効なメールではありません"
        break

      case "ConfirmEmail":
        errors.ConfirmEmail =
          value !== userData.Email
            ? "必須項目を必ずご記載の上、ご送信ください。"
            : ""
        break
      case "Country":
        errors.Country =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      case "Request":
        errors.Request =
          value === "" ? "必須項目を必ずご記載の上、ご送信ください。" : ""
        break
      default:
        break
    }
  }
  const [addLp] = useMutation(LpPage02Register, {
    onCompleted: () => {
      localStorage.setItem("thankload", "1")
      navigate("/thankyou")
    },
    onError: () => {
      setLoading(false)
      toast.error("サーバーエラー")
    },
  })

  const handleRegisterLp = () => {
    let auxCountry = userData.Country
    if (userData.OCountry) {
      auxCountry = userData.OCountry
    }
    addLp({
      variables: {
        input: {
          data: {
            FullName: userData.FullName,
            Email: userData.Email,
            Country: auxCountry,
            Request: userData.Request,
            IpAddress: ip,
            IpCountry: ipAddress,
          },
        },
      },
    })
  }

  const [captchaVerify, setCaptchaVerify] = useState(false)
  const recaptchaVerify = response => {
    if (response) {
      setUserData(prevState => ({
        ...prevState,
        isVerified: true,
      }))
    } else {
      setCaptchaVerify(true)
      setTimeout(() => {
        setCaptchaVerify(false)
      }, 3000)
    }
  }
  const formValidate = (errors, ...data) => {
    let valid = true
    Object.values(errors).forEach(val => {
      val && (valid = false)
    })
    Object.values(data).forEach(val => {
      val === "" && (valid = false)
    })
    return valid
  }
  const handleSubmit = event => {
    event.preventDefault()
    if (
      userData.FullName !== "" &&
      userData.Email !== "" &&
      userData.Country !== "" &&
      userData.Request !== ""
    ) {
      if (formValidate(userData.messageError, userData)) {
        setLoading(true)
        handleRegisterLp()
      } else {
        toast.error("フォームが正しくありません")
      }
    } else {
      toast.error("入力内容を再度ご確認ください。")
    }
  }
  
  useEffect(() => {
    // Add the async gtag script
    const gtagScript = document.createElement('script');
    gtagScript.src = "https://www.googletagmanager.com/gtag/js?id=AW-823316446";
    gtagScript.async = true;
    document.head.appendChild(gtagScript);

    // Add the gtag initialization script
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'AW-823316446');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      // Cleanup the scripts when the component unmounts
      document.head.removeChild(gtagScript);
      document.head.removeChild(inlineScript);
    };
  }, []);
  useEffect(() => {
    // Add the Facebook Pixel main script
    const fbqScript = document.createElement('script');
    fbqScript.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '589949796691668');
      fbq('track', 'PageView');
    `;
    document.head.appendChild(fbqScript);

    // Add the noscript fallback
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=589949796691668&ev=PageView&noscript=1" />
    `;
    document.body.appendChild(noscript);

    return () => {
      // Cleanup the added scripts when the component unmounts
      document.head.removeChild(fbqScript);
      document.body.removeChild(noscript);
    };
  }, []);

  return (
    <>
      <header className="lp02-header">
        
        <button className="btn-menu" aria-label="btn-menu">
          <input
            className="menu-btn"
            type="checkbox"
            id="menu-btn"
            name="menu-btn"
          />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon">{""}</span>
          </label>
          <ul className="menu">
            <li>
              <a href="/">
                <span>レジュメ簡単登録</span>
              </a>
            </li>
            <li>
              <a href="/">
                <span>アメリカ求人の参考例</span>
              </a>
            </li>
            <li>
              <a href="/">
                <span>クイックUSAが選ばれる理由</span>
              </a>
            </li>
            <li>
              <a href="/">
                <span>会社概要</span>
              </a>
            </li>
          </ul>
        </button>
        <div className="header-style">
          <div className="header-image">
            <img src={logo1} alt="logo919mexico" />
          </div>
          <div className="midsize-header">
            <p>お電話：52-449-153-1400</p>
          </div>
        </div>
      </header>
      <body className="lp02-body">
        <Lp02>
          <div id="primary-banner">
            <div className="banner-content">
              <h1>メキシコでの就職・転職</h1>
              <p className="desktop">
                Quick Global Mexico がゴールまでフルサポートいたします！
              </p>
              <p className="mobile">ゴールまでフルサポートいたします！</p>
              <a className="desktop banner-button" href="#registerForm" aria-label="banner-button">
                <div className="button-text">
                  <span className="bt1">今すぐ無料相談</span>
                </div>
                <div className="arrow right"></div>
              </a>
            </div>
          </div>
          <div className="mobile banner-button-container">
            <a className="banner-button" href="#registerForm">
              <p className="button-text">今すぐ無料相談</p>
            </a>
          </div>

          <div className="container-page">
            <h2>メキシコ求人の参考例</h2>
            <p className="subt-text">
              クイックグローバルメキシコでご紹介【無料】している求人の参考例です。
            </p>
            <input id="open-card" type="checkbox" value="" />
            <label for="open-card" className="button-open-cards dc1">
              {" "}
              <div className="arrow"></div>{" "}
            </label>
            <div className="element-cards margin-bottom110">
              <div className="element-card">
                <div className="job-ofert">
                  <img src={ref1} alt="ref1" />
                  <p>事務兼通訳</p>
                  <p>
                    <img src={money} alt="money-logo" /> 2万5千～4万ペソ
                  </p>
                  <p>約17万円〜27万1千円</p>
                  <p>*1ペソ＝6.78円、2022年6月時点</p>
                  <p>
                    <img src={location} alt="location-logo" />
                    ケレタロ州勤務
                  </p>
                </div>
              </div>
              <div className="element-card">
                <div className="job-ofert">
                  <img src={ref2} alt="ref2" />
                  <p>製造技術職マネージャー</p>
                  <p>
                    <img src={money} alt="money-logo" />
                    5万～8万ペソ
                  </p>
                  <p>約33万9千円〜54万2千円</p>
                  <p>*1ペソ＝6.78円、2022年6月時点</p>
                  <p>
                    <img src={location} alt="location-logo" />
                    グアナファト州勤務
                  </p>
                </div>
              </div>
              <div className="element-card">
                <div className="job-ofert">
                  <img src={ref3} alt="ref3" />
                  <p>営業職</p>
                  <p>
                    <img src={money} alt="money-logo" />
                    3万～6万ペソ
                  </p>
                  <p>約20万3千円〜40万6千円</p>
                  <p>*1ペソ＝6.78円、2022年6月時点</p>
                  <p>
                    <img src={location} alt="location-logo" />
                    アグアスカリエンテス州勤務
                  </p>
                </div>
              </div>
            </div>
            <h2>クイックグローバルメキシコが選ばれる理由</h2>
            <p className="subt-text">
              クイックグローバルメキシコのサービスが求職者の皆様に“圧倒的”に支持される理由を５つご紹介
            </p>
            <input id="open-card2" type="checkbox" value="" />
            <label for="open-card2" className="button-open-cards dc2">
              {" "}
              <div className="arrow"></div>{" "}
            </label>
            <div className="section2 margin-bottom110">
              <div className="grey-cards-container margin-bottom30">
                <div className="grey-cards">
                  <div className="why-mexico">
                    <img src={why_icon1} alt="why_icon1" />
                    <p className="grey-cards-title">
                      <span>大手企業の求人多数</span>
                    </p>
                    <p>
                      当社では長年の経験や実績をもとに、在メキシコ
                      の日系企業の求人を多く保有しています。ニッチな分野から名前を聞いたことがある大手まで、新着求人を日々ご紹介します。
                    </p>
                  </div>
                </div>
                <div className="grey-cards">
                  <div className="why-mexico">
                    <img src={why_icon2} alt="why_icon2" />
                    <p className="grey-cards-title">
                      <span>日本人によるきめ細やかなサポート</span>
                    </p>
                    <p>
                      カウンセリングを通じてご経歴やご希望を伺いながら、求職者と企業の間に立ちフルサポートします。履歴書や面接前のアドバイスも提供し、入社後のフォローも行います。日本人が100%対応しますので、お気兼ねなくご連絡ください。
                    </p>
                  </div>
                </div>
                <div className="grey-cards">
                  <div className="why-mexico">
                    <img src={why_icon3} alt="why_icon3" />
                    <p className="grey-cards-title">
                      <span>完全無料で就職サポート</span>
                    </p>
                    <p>
                      費用は一切無料です。当社の求職者へのサービスは手数料などはかからず、登録から入社に至るまで、全て無料で行っています。
                    </p>
                  </div>
                </div>
                <div className="grey-cards">
                  <div className="why-mexico">
                    <img src={why_icon4} alt="why_icon4" />
                    <p className="grey-cards-title">
                      <span>メキシコ 転職に強い</span>
                    </p>
                    <p>
                      日本に本社を持つ日系の人材会社として早くからメキシコ
                      に進出しており、幅広い情報を保有しています。日本とは異なる転職活動をあらゆる業種や職種に精通したリクルーティング・コンサルタントがお手伝いします。
                    </p>
                  </div>
                </div>
                <div className="grey-cards">
                  <div className="why-mexico">
                    <img src={why_icon5} alt="why_icon5" />
                    <p className="grey-cards-title">
                      <span>クロスボーダーリクルートメント</span>
                    </p>
                    <p>
                      クイックグループはメキシコ
                      、日本、米国、中国、ベトナム、英国、タイにおいて、人材総合サービスを提供しています。
                    </p>
                  </div>
                </div>
              </div>
              <div className="space15"></div>
              <a className="why-button" href="#registerForm">
                <span>今すぐ無料相談</span>
                <div className="arrow2 right"></div>
              </a>
            </div>

            <h2>
              自分一人で行う転職活動と、クイックグローバルメキシコ
              のサービスを利用した場合との違い
            </h2>
            <p className="subt-text">
              ご存知でしたか？自分だけで就職活動をするよりもクイックグローバルメキシコとの就職活動が断然有利！
            </p>
            <input id="open-card3" type="checkbox" value="" />
            <label for="open-card3" className="button-open-cards dc3">
              {" "}
              <div className="arrow"></div>{" "}
            </label>
            <div className="difference margin-bottom50 not-mobile-display">
              <img src={difference} alt="difference-section" />
            </div>
            <div className="margin-bottom30 margin-top30 mobile-display-dif section3">
              <p className="difference_title">1、自己分析</p>
              <div className="difference margin-bottom50">
                <div className="difference-card">
                  <p className="difference_subtitle">
                    クイックグローバルメキシコ
                  </p>
                  <img src={happy} alt="happy-head" />
                  <p className="difference_text">
                    コンサルタントとのカウンセリングを受け、
                    <span>客観的なキャリア診断</span>をもらい、適性を理解する
                  </p>
                </div>
                <div className="difference-card">
                  <p className="difference_subtitle">自分一人だと</p>
                  <img src={unhappy} alt="happy-head" />
                  <p className="difference_text">
                    自身で自己分析を行い、自分に合った業種を探す
                  </p>
                </div>
              </div>
              <p className="difference_title">2、求人探し</p>
              <div className="difference margin-bottom50">
                <div className="difference-card">
                  <p className="difference_subtitle">
                    クイックグローバルメキシコ
                  </p>
                  <img src={happy} alt="happy-head" />
                  <p className="difference_text">
                    <span>個人では見つけられない</span>
                    、適性やニーズに最も合った仕事を紹介してもらい、希望の求人を選ぶ
                  </p>
                </div>
                <div className="difference-card">
                  <p className="difference_subtitle">自分一人だと</p>
                  <img src={unhappy} alt="happy-head" />
                  <p className="difference_text">
                    求人サイトや知人の紹介などで希望にあう求人を探し出す
                  </p>
                </div>
              </div>
              <p className="difference_title">3、情報収集・レジュメ作成</p>
              <div className="difference margin-bottom50">
                <div className="difference-card">
                  <p className="difference_subtitle">
                    クイックグローバルメキシコ
                  </p>
                  <img src={happy} alt="happy-head" />
                  <p className="difference_text">
                    <span>企業や求人内容を十分に説明</span>
                    、レジュメ作成や面接のアドバイスをもらう
                  </p>
                </div>
                <div className="difference-card">
                  <p className="difference_subtitle">自分一人だと</p>
                  <img src={unhappy} alt="happy-head" />
                  <p className="difference_text">
                    スペイン語サイトから企業情報をまとめ、スペイン語のレジュメ・カバーレターを作成
                  </p>
                </div>
              </div>
              <p className="difference_title">4、応募、書類選考</p>
              <div className="difference margin-bottom50">
                <div className="difference-card">
                  <p className="difference_subtitle">
                    クイックグローバルメキシコ
                  </p>
                  <img src={happy} alt="happy-head" />
                  <p className="difference_text">
                    <span>クイックグローバルメキシコから企業に推薦</span>
                    してもらう（複数企業への同時応募も可能）
                  </p>
                </div>
                <div className="difference-card">
                  <p className="difference_subtitle">自分一人だと</p>
                  <img src={unhappy} alt="happy-head" />
                  <p className="difference_text">
                    履歴書、職務経歴書、スペイン語レジュメを添えて企業へ応募する
                  </p>
                </div>
              </div>
              <p className="difference_title">5、面接</p>
              <div className="difference margin-bottom50">
                <div className="difference-card">
                  <p className="difference_subtitle">
                    クイックグローバルメキシコ
                  </p>
                  <img src={happy} alt="happy-head" />
                  <p className="difference_text">
                    面接の調整や<span>給与/待遇の交渉</span>
                    を代行してもらい、面接前のアドバイスも受けられる
                  </p>
                </div>
                <div className="difference-card">
                  <p className="difference_subtitle">自分一人だと</p>
                  <img src={unhappy} alt="happy-head" />
                  <p className="difference_text">
                    面接の設定や準備、給与/待遇の交渉は自分で行う
                  </p>
                </div>
              </div>
              <p className="difference_title">6、内定・入社</p>
              <div className="difference margin-bottom50">
                <div className="difference-card">
                  <p className="difference_subtitle">
                    クイックグローバルメキシコ
                  </p>
                  <img src={happy} alt="happy-head" />
                  <p className="difference_text">
                    合否の連絡を受ける。内定後も必要に応じクイックグローバルメキシコに相談できる
                  </p>
                </div>
                <div className="difference-card">
                  <p className="difference_subtitle">自分一人だと</p>
                  <img src={unhappy} alt="happy-head" />
                  <p className="difference_text">
                    合否の結果を確認。内定後は採用条件や入社日などを調整し、入社に向けて準備をする
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="form-back margin-bottom80" id="registerForm">
            <h2 className="margin-bottom30">無料相談フォーム</h2>
            <form className="form-style" onSubmit={handleSubmit}>
              <div className="form-center">
                <label htmlFor="FullName">
                  氏名(フルネーム) <span>*</span>
                </label>
                <div className="input-container margin-bottom40">
                  <div className="col-md-12" style={{ padding: 0 }}>
                    <input
                      type="text"
                      placeholder="Full Name"
                      name="FullName"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </div>
                </div>
                <label htmlFor="Email">
                  メールアドレス<span>*</span>
                </label>
                <div className="input-container margin-bottom40">
                  <div className="col-md-12" style={{ padding: 0 }}>
                    <input
                      type="text"
                      placeholder="Email Address"
                      name="Email"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </div>
                  <div className="col-md-12" style={{ padding: 0 }}>
                    <input
                      type="text"
                      placeholder="Email Address (confirm)"
                      name="ConfirmEmail"
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </div>
                </div>
                <label htmlFor="Country">
                  在住国<span>*</span>
                </label>
                <div
                  className="input-container margin-bottom40"
                  id="country-mar"
                >
                  <div className="col-md-12" style={{ padding: 0 }}>
                    <select
                      id="country"
                      name="Country"
                      onChange={handleChange}
                      onBlur={handleChange}
                    >
                      <option value="" disabled selected>
                        Select Country
                      </option>
                      <option>Mexico</option>
                      <option>Japan</option>
                      <option>Other Country</option>
                    </select>
                  </div>
                  {userData.Country === "Other Country" && (
                    <div className="col-md-12" style={{ padding: 0 }}>
                      <input
                        type="text"
                        placeholder="Other"
                        name="OCountry"
                        id="ocountry"
                        onChange={handleChange}
                        onBlur={handleChange}
                      />
                    </div>
                  )}
                </div>
                <label htmlFor="Request">
                  その他ご希望<span>*</span>
                </label>
                <div className="input-container margin-bottom40">
                  <div className="col-md-12" style={{ padding: 0 }}>
                    <textarea
                      type="textarea"
                      placeholder="Request"
                      name="Request"
                      onChange={handleChange}
                      onBlur={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <hr className="margin-bottom30" />
              <div className="captcha-style margin-bottom30">
                {isBrowser && (
                  <Recaptcha
                    render="explicit"
                    sitekey="6Lf4VfAbAAAAAO1Fu6ZqpDdvbs929UMuN04vwA3A"
                    name="isVerify"
                    verifyCallback={recaptchaVerify}
                  />
                )}

                {captchaVerify ? (
                  <span className="field-validation-captcha">
                    Please verify that you are not a robot
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="terms-style margin-bottom30">
                <p className="terms">
                  当社の
                  <a
                    className="termsofuse"
                    href="/termsofuse/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    利用規約・個人情報保護方針
                  </a>
                  に同意の上、お申込みください。
                </p>
              </div>
              <div className="form-button-style margin-bottom50">
                {loading === false ? (
                  <input
                    type="submit"
                    value="申し込む"
                    className="btn-submit"
                  />
                ) : (
                  <p className="buttonload buttonload-lp">
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                    &nbsp;&nbsp;少々お待ちください
                  </p>
                )}
              </div>
            </form>
          </div>
          <div className="container-page">
            <h2 className="margin-bottom30">
              クイックグローバルメキシコの会社概要
            </h2>
            {/* <p className="subt-text mobile-display">
              QUICK USA,
              Inc.は⼈材紹介・⼈材派遣をはじめとする総合⼈材サービス事業を北米で展開しています。
              <br></br>
              グローバルな視点で⼈材ネットワークを構築し、登録者の皆様には頼りになるリクルーティング・コンサルタントとして、企業の⼈事担当の皆様には、プロフェッショナルな⼈事戦略パートナーとして、誠実に且つ確実に、そのニーズにお応えしてまいります。
            </p> */}
            <input id="open-card4" type="checkbox" value="" />
            {/* <label for="open-card4" className="button-open-cards dc4">
              {" "}
              <div className="arrow"></div>{" "}
            </label> */}
            <div className="section4 margin-bottom80">
              <div className="sec4-1 margin-bottom50">
                <div className="col-md-6" style={{ flexBasis: 50 + "%" }}>
                  <div className="profile-919mexico">
                    <img src={building} alt="building" />
                  </div>
                </div>
                <div
                  className="col-md-6 margin-left30r"
                  style={{ flexBasis: 50 + "%" }}
                >
                  <div className="profile-919mexico">
                    <p>
                      {/* OLD TEXT */}
                      {/* QUICK GLOBAL MEXICO, S.A. DE C.V.
                      は日本の株式会社クイック（コード番号：4318
                      東証プライム市場上場）のメキシコ
                      法人です。アグアスカリエンテスを拠点に、クイックグループの一員として、人と企業を結ぶ事業に取り組み「関わった⼈全てをハッピーに」というクイックグループの経営理念の下、⼈材紹介・⼈材派遣をはじめとする総合⼈材サービス事業をメキシコ
                      で展開しています。グローバルな視点で⼈材ネットワークを構築し、登録者の皆様には頼りになるリクルーティング・コンサルタントとして、企業の⼈事担当の皆様には、プロフェッショナルな⼈事戦略パートナーとして、誠実に且つ確実に、そのニーズにお応えしてまいります。
                      <br /> */}
                      {/* END OLD TEXT */}
                      QUICK GLOBAL MEXICO, S.A. DE C.V.
                      は日本の株式会社クイック（証券コード：4318
                      東証プライム市場上場）のメキシコ法人です。アグアスカリエンテスを拠点に、クイックグループの一員として、人と企業を結ぶ事業に取り組み「関わった⼈全てをハッピーに」というクイックグループの経営理念の下、⼈材紹介をはじめとする総合⼈材サービス事業をメキシコで展開しています。グローバルな視点で⼈材ネットワークを構築し、登録者の皆様には頼りになるリクルーティング・コンサルタントとして、企業の⼈事担当の皆様には、プロフェッショナルな⼈事戦略パートナーとして、誠実に且つ確実に、そのニーズにお応えしてまいります。
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex md-flex-col">
                <div
                  className="d-flex flex-col u-margin-right-2 md-margin-none"
                  style={{ marginBottom: "2em" }}
                >
                  <p className="profile2-919mexico-title">
                    アグアスカリエンテス （本社）{" "}
                  </p>
                  <div className="" style={{ flexBasis: 50 + "%" }}>
                    <div className="profile-919mexico">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.345294708472!2d-102.29288848555852!3d21.92127326208685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429eeffdd85ca43%3A0x16a5d242ee91af58!2sQUICK%20GLOBAL%20MEXICO%2C%20S.A.%20DE%20C.V.!5e0!3m2!1sen!2sbo!4v1651696303145!5m2!1sen!2sbo"
                        width=""
                        height="350"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title="profile-919mexico"
                      ></iframe>
                    </div>
                  </div>
                  <div
                    className="u-margin-top-2 sec4-1-2"
                    // style={{ flexBasis: 50 + "%" }}
                  >
                    <div className="profile-info">
                      <p>
                        <img src={location} alt="location-logo" />
                        C.C. Punto 45, Boulevard A Zacatecas 845, Oficina 510,
                        Desarrollo Especial Bulevar a Zacatecas, C.P.20116
                        Aguascalientes, Ags.
                      </p>
                      <p>
                        <img src={phone} alt="phone-logo" />
                        +52-449-107-5308
                      </p>
                      <p>
                        <img src={mail} alt="mail-logo" />
                        quick@919mexico.com
                      </p>
                      <p>
                        <img src={world} alt="world-logo" />
                        <a
                          href="http://www.919mexico.com"
                          target="_blank"
                          style={{ paddingLeft: 0 }}
                          rel="noopener noreferrer"
                        >
                          919mexico.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-col u-margin-left-2 md-margin-none">
                  <p className="profile2-919mexico-title">ケレタロ </p>
                  <div className="" style={{ flexBasis: 50 + "%" }}>
                    <div className="profile-919mexico">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3735.3668695399037!2d-100.36143041534017!3d20.57307021372023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d344bcafacc4bf%3A0xf7471c51ba04636e!2sAv.%20Armando%20Birlain%20Shaffler%202001-Piso%2015A%2C%20Centro%20Sur%2C%2076090%20Santiago%20de%20Quer%C3%A9taro%2C%20Qro.%2C%20Mexico!5e0!3m2!1sen!2sbo!4v1691690459397!5m2!1sen!2sbo"
                        width="100%"
                        height="350"
                        style={{ border: 0 }}
                        loading="lazy"
                        title="Querétaro Office"
                      ></iframe>
                    </div>
                  </div>
                  <div
                    className="u-margin-top-2 sec4-1-2"
                    // style={{ flexBasis: 50 + "%" }}
                  >
                    <div className="profile-info">
                      <p>
                        <img src={location} alt="location-logo" />
                        Av. Armando Birlain 2001 Corporativo 1 Piso 15A Colonia
                        Centro Sur (Complejo Central Park) Santiago de
                        Querétaro, Qro. C.P 76090
                      </p>
                      <p>
                        <img src={phone} alt="phone-logo" />
                        +52-449-107-5308
                      </p>
                      <p>
                        <img src={mail} alt="mail-logo" />
                        quick@919mexico.com{" "}
                      </p>
                      <p>
                        <img src={world} alt="world-logo" />
                        <a
                          href="http://www.919mexico.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ paddingLeft: 0 }}
                        >
                          919mexico.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Lp02>
      </body>
      <footer className="lp02-footer">
        <div className="copyright">
          <p>
            Copyright © QUICK GLOBAL MEXICO, S.A. DE C.V. All Rights Reserved.
          </p>
        </div>
      </footer>
    </>
  )
}

export default Lp_02

export const Head = () => {
  return (
    <Seo
      title="メキシコでの就職・転職"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/lp02`}
    />
  )
}
